
.custom-pill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 44px;
  background: #A132FF;

  .custom-pill-label {
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    line-height: 8px;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    font-style: normal;
  }
}
